import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { PageSubTitle } from '../Text';


const Container = styled.div`
    max-width: 1280px;
    margin: 20px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.media.littleScreen`
      width: calc(100% - 20px);
    `}
`;

const BlackSubtitle = styled.span`
    font-weight: bolder;
    margin-left: 6px;
`;

const List = styled.ul`
    margin: 20px 0px;
    width: 70%;

    ${({ theme }) => theme.media.mobile`
      width: initial;
    `}
`;

const ListItem = styled.li`
    margin: 25px 0px;
    list-style: none;
    position: relative;
    line-height: 24px;

    &:before {
        content: "";
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background-color: ${({ theme }) => theme.colors.mainBlue.hex};
    }
`;

const Engagements = () => {
    const { t } = useTranslation('translation');

    return (
        <Container>
            <PageSubTitle>
                {t('home_engagement_title')}
                <BlackSubtitle>
                    2020
                </BlackSubtitle>
            </PageSubTitle>
            <List>
                <ListItem>{t('home_engagement_text1')}</ListItem>
                <ListItem>{t('home_engagement_text2')}</ListItem>
                <ListItem>{t('home_engagement_text3')}</ListItem>
            </List>
        </Container>
    );
};

export default React.memo(Engagements);
