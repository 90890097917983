import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';


import ProductBlock from '../ProductBlock';

const Container = styled.div`
    width: 100%;
    position: relative;
`;

const Dot = styled.div`
  position: absolute;
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;
  display: flex;
  bottom: 10px;
  justify-content: center;
  align-items: flex-end;
  left: 10px;
  right: 10px;
  z-index: 8;

  li {
    button {
      position: relative;
      background-color: ${({ theme }) => theme.colors.white.hex};
      height: 14px;
      width: 14px;
      border: 1px solid ${({ theme }) => theme.colors.mainBlue.hex};
      margin: 0;
      padding: 0;

      &:before {
        content: none;
      }
    }

    &.slick-active button:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: ${({ theme }) => theme.colors.mainBlue.hex};;
      height: 8px;
      width: 8px;
    }
  }

  ${({ theme }) => theme.media.littleScreen`
    width: calc(100% - 20px);
  `}
`;

const dotsRender = (dots) => (
    <Dot>{dots}</Dot>
);

const SliderProducts = () => {
    return (
        <Container>
            <Slider
                dots
                infinite
                autoplay
                speed={300}
                arrows={false}
                slidesToShow={1}
                slidesToScroll={1}
                autoplaySpeed={6000}
                appendDots={dotsRender}
            >
                <ProductBlock keyProduct="2"
                    imgSrc="images/resistance-square.png" />
                <ProductBlock keyProduct="1"
                    imgSrc="images/dalle.png" />
            </Slider>
        </Container>
    );
};

export default React.memo(SliderProducts);
