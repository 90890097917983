import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

import Career from '../components/index/Career';
import Company from '../components/index/Company';
import Products from '../components/index/Products';
import Engagements from '../components/index/Engagements';

const Container = styled.div`

`;

const Banner = styled.div`
  width: 100%;
  min-height: 300px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/images/usine_pano.JPG');
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;

  ${({ theme }) => theme.media.littleScreen`
  min-height: 200px;
  `}
`;

const BannerText = styled.h1`
  max-width: 1280px;
  margin: 0 auto;
  width: 80%;
  font-weight: bold;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.white.hex};
  text-shadow: 0px 4px 4px rgba(${({ theme }) => theme.colors.black.rgb}, 0.6);

  ${({ theme }) => theme.media.littleScreen`
    font-size: 38px;
  `}
`;

const IndexPage = () => {
    const { t } = useTranslation('translation');

    return (
        <Layout>
            <Helmet>
                <title>
                    {t('home_meta_title')}
                </title>
                <meta name="description"
                    content={t('home_meta_description')} />
            </Helmet>
            <Container>
                <Banner>
                    <BannerText>
                        {t('home_banner')}
                    </BannerText>
                </Banner>
                <Company />
                <Products />
                <Engagements />
                <Career />
            </Container>
        </Layout>
    );
};

export default IndexPage;
