import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    PageSubTitle, MainText
} from '../Text';
import LinkButtonSample from '../StyledLocalizedLink';

const Container = styled.div`
    background-color: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.15);
    padding: 60px 0px;
`;

const ContentContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    ${({ theme }) => theme.media.littleScreen`
      width: calc(100% - 10px);
      overflow: hidden;
    `}
`;

const LinkButton = styled(LinkButtonSample)`
    margin-top: 25px;
`;

const PageSubTitleStyled = styled(PageSubTitle)`
    text-transform: uppercase;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 60%;
        z-index: 0;
        background-color:${({ theme }) => theme.colors.mainBlue.hex};
    }
    &:after {
        right: -60%;
    }
    &:before {
        left: -60%;
    }
`;

const MainTextStyled = styled(MainText)`
    font-size: 18px;
    text-align: center;
`;

const Career = () => {
    const { t } = useTranslation('translation');

    return (
        <Container>
            <ContentContainer>
                <PageSubTitleStyled>
                    {t('we_recruit')}
                </PageSubTitleStyled>
                <MainTextStyled>
                    {t('home_career_text')}
                </MainTextStyled>
                <LinkButton to="career">
                    {t('recruiting')}
                </LinkButton>
            </ContentContainer>
        </Container>
    );
};

export default React.memo(Career);
