import styled from 'styled-components';

export const MainContainer = styled.section`
max-width: 1280px;
margin: 0 auto;
width: 80%;
display: flex;
flex-direction: column;
padding: 20px 0px;

${({ theme }) => theme.media.littleScreen`
  width: calc(100% - 40px);
`}

${({ theme }) => theme.media.tablet`
  width: calc(100% - 20px);
`}
`;
