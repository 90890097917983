import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import config from '../../gatsby-config';

const StyledLink = styled(Link)`
    background: ${({ theme }) => theme.colors.mainBlue.hex};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white.hex};
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    padding: 10px 25px;
    margin: 15px;
    transition: background 0.4s ease;
    cursor: pointer;

    &:hover {
        background: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.9);
    }
`;
const StyledA = styled.a`
    background: ${({ theme }) => theme.colors.mainBlue.hex};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.white.hex};
    border-radius: 3px;
    display: inline-block;
    text-decoration: none;
    padding: 10px 25px;
    margin: 15px;
    transition: background 0.4s ease;
    cursor: pointer;

    &:hover {
        background: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.9);
    }
`;

export const getLocalizedPath = (language, to) => {
    const path = to.replace(/\\|\//g, '');

    return config.siteMetadata.routes[language][path];
};

const LocalizedLink = ({
    to, ...props
}) => {
    const { i18n } = useTranslation();
    const path = getLocalizedPath(i18n.language, to);
    const pathName = to.replace(/\\|\//g, '');

    if (to.indexOf('http') !== -1) {
        return <StyledA
            aria-label={'Link to external url'}
            {...props}
            href={to}>
            {props.children}
        </StyledA>;
    }

    return <StyledLink
        aria-label={`Link to ${pathName} in ${i18n.language}`}
        {...props}
        to={path}>
        {props.children}
    </StyledLink>;
};

LocalizedLink.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.any
};

export default React.memo(LocalizedLink);
