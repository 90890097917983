import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LinkButtonSample from './StyledLocalizedLink';

const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  ${({ theme }) => theme.media.littleScreen`
    width: calc(100% - 20px);
    margin: 0 10px;
  `}

  ${({ theme }) => theme.media.mobile`
    flex-direction: column;
  `}
`;

const ProductImg = styled.img`
    max-width: 42%;
    object-fit: contain;
    
    ${({ theme }) => theme.media.mobile`
      max-width: 80%;
    `}
`;

const ContentBlock = styled.div`
    width: 50%;

    ${({ theme }) => theme.media.mobile`
      width: 98%;
      margin-top: 10px;
    `}
`;

const ProductTitle = styled.span`
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.mainBlue.hex};
`;

const ProductDescription = styled.p`
    font-size: 16px;
    white-space: pre-wrap;
    margin-top: 30px;
`;

const LinkButton = styled(LinkButtonSample)`
    margin: 0 0 0 auto;
    display: table;
`;

const ProductBlock = ({
    keyProduct, imgSrc, noButton
}) => {
    const { t } = useTranslation('translation');

    return (
        <Container>
            <ProductImg src={imgSrc}
                alt={t(`product_title_${keyProduct}`)} />
            <ContentBlock>
                <ProductTitle>
                    {t(`product_title_${keyProduct}`)}
                </ProductTitle>
                <ProductDescription dangerouslySetInnerHTML={{ __html: t(`product_description_${keyProduct}`) }} />
                {!noButton ? (
                    <LinkButton to="products">
                        {t('learn_more')}
                    </LinkButton>
                ) : null}
            </ContentBlock>
        </Container>
    );
};

ProductBlock.propTypes = {
    keyProduct: PropTypes.string.isRequired,
    imgSrc: PropTypes.string.isRequired,
    noButton: PropTypes.bool
};

ProductBlock.defaultProps = {noButton: false};

export default React.memo(ProductBlock);
