import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
    BlockTitle, MainText
} from '../Text';
import { MainContainer } from '../Container';
import LinkButton from '../StyledLocalizedLink';

const BlockImageDesktop = styled.img`
  max-width: 46%;
  object-fit: contain;

  ${({ theme }) => theme.media.tablet`
      display: none;
  `}
`;

const BlockImageMobile = styled.img`
  display: none;

  ${({ theme }) => theme.media.tablet`
    display: block;
    max-width: 80%;
    margin: 0 auto;
    max-height: 300px;
  `}
`;

const Row = styled.div`
  display: flex;

  ${({ theme }) => theme.media.tablet`
    flex-direction: column;
  `}
`;

const Block = styled.div``;

const Company = () => {
    const { t } = useTranslation('translation');

    return (
        <MainContainer>
            <BlockTitle>
                {t('company')}
            </BlockTitle>
            <Row>
                <Block>
                    <MainText>
                        {t('company_history_text')}
                    </MainText>
                    <BlockImageMobile
                        src="/images/company.jpg"
                        alt="Usine fabrication résistances"
                    />
                    <BlockTitle>
                        {t('home_expert_title')}
                    </BlockTitle>
                    <MainText>
                        {t('home_expert_text')}
                    </MainText>
                    <MainText>
                        {t('home_expert_text_2')}
                    </MainText>
                    <LinkButton to="company">
                        {t('company')}
                    </LinkButton>
                </Block>
                <BlockImageDesktop
                    src="/images/company.jpg"
                    alt="Usine fabrication résistances"
                />
            </Row>
        </MainContainer>
    );
};

export default React.memo(Company);
