import React from 'react';
import styled from 'styled-components';

import SliderProducts from './SliderProducts';

const Container = styled.div`
  margin: 60px 0px;
  padding: 20px 0px;
  position: relative;
  min-height: 400px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;

  ${({ theme }) => theme.media.littleScreen`
    margin: 20px 0px;
  `}
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: -1;
  background-color: rgba(${({ theme }) => theme.colors.mainBlue.rgb}, 0.15);


  ${({ theme }) => theme.media.tablet`
    top: 0;
    height: 100%;
  `}
`;

const Products = () => (
    <Container>
        <BackgroundColor />
        <SliderProducts />
    </Container>
);
export default React.memo(Products);
